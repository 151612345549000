<template>
  <div>
    <!-- <div class="title">本月一共{{ Lists.length }}人打卡。</div> -->
    <ul
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
      v-if="Lists"
    >
      <div class="van-clearfix">
        <div class="float-item" v-for="(item, index) in Lists" :key="item.uid">
          <van-collapse v-model="activeName" accordion>
            <van-collapse-item
              :title="item.name"
              :name="index"
              v-if="item.data.length > 0"
            >
              <div v-for="user in item.data" :key="user.clockid">
                <div class="list">
                  {{ user.title }} 打卡 {{ user.clockinnum }} {{ user.num }}
                  {{ user.unit }}.
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </ul>
    <van-loading v-else size="24.001px" vertical
      >本月打卡信息加载中...</van-loading
    >
  </div>
</template>

<script>
export default ({
  data () {
    return {
      activeName: '1',
      num: 0
    }
  },
  mounted () {
    if (this.$store.state.systeminfo.thisMonth.length === 0) {
      this.$store.dispatch('thisMonth', {
        uid: localStorage.getItem('uid'),
        classid: localStorage.getItem('classid')
      })
    }
  },
  computed: {
    Lists: {
      get () {
        return this.$store.state.systeminfo.thisMonth
      },
      set () { }
    }
  }
})
</script>

<style lang="scss" scoped>
.title {
  color: chocolate;
  padding: 1.389vw;
  text-align: left;
  font-size: 3.611vw;
  background-color: cornsilk;
}
.list {
  color: chocolate;
  font-size: 3.611vw;
  padding-left: 2.778vw;
  padding-top: 0.833vw;
  padding-bottom: 0.833vw;
  border-bottom: 0.278vw dotted chocolate;
  text-align: left;
}
::v-deep .van-cell__title {
  text-align: left;
}
</style>
