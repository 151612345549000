<template>
  <div>
    <NavbarCommon :title="title"></NavbarCommon>
    <!-- <div class="title">精进日志</div> -->
    <van-notice-bar
      left-icon="volume-o"
      text="本页只显示您所在班级的成员打卡及学习信息。"
    />
    <!-- <div class="tip">本页只显示您所在班级的成员打卡及学习信息。</div> -->
    <van-tabs v-model="active">
      <van-tab title="今日"
        ><br />
        <Systemtoday></Systemtoday>
      </van-tab>
      <van-tab title="本月"
        ><br />
        <Systemmonth></Systemmonth>
      </van-tab>
      <van-tab title="所有"
        ><br />
        <SystemList></SystemList
      ></van-tab>
      <van-tab title="测试"
        ><br />
        <Systemtest></Systemtest
      ></van-tab>
    </van-tabs>
    <!-- <div class="marginbottom"></div> -->
  </div>
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
import SystemList from '@/components/common/SystemList'
import Systemmonth from '@/components/common/Systemmonth'
import Systemtoday from '@/components/common/Systemtoday'
import Systemtest from '@/components/common/Systemtest'
export default {
  components: {
    SystemList, Systemmonth, Systemtoday, Systemtest, NavbarCommon
  },
  data () {
    return {
      title: '精进日志',
      active: '1'
    }
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  }
}
</script>

<style lang='scss' scoped>
.title {
  color: white;
  background-color: rgb(70, 27, 27);
  line-height: 5.556vw;
  font-size: 4.167vw;
  height: 9.722vw;
  margin: 0 auto;
  padding-top: 4.167vw;
  text-align: center;
}
.marginbottom {
  height: 14.444vw;
}
</style>
