<template>
  <div>
    <ul
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
      v-if="Lists"
    >
      <div class="van-clearfix pointlist">
        <div
          class="float-item"
          v-for="(course, index) in Lists"
          :key="course.id"
        >
          <van-collapse
            v-model="activeName"
            accordion
            v-if="course.ctype == 1 || course.ctype == 4"
          >
            <van-collapse-item :title="course.cname" :name="index">
              <div v-for="point in course.points" :key="point.pointid">
                <Testinfopoint :point="point"></Testinfopoint>
                <!-- <van-collapse v-model="pointname" accordion>
                  <van-collapse-item :title="point.pname" :name="point.pointid">
                    <div v-for="user in point.usergroup" :key="user.uid">
                      <div class="list">
                        {{ user.name }} 测试 {{ user.totalnum }} 次 / 最高分
                        {{ user.max }} / 最低分 {{ user.min }} / 平均分
                        {{ user.avg }}
                      </div>
                    </div>
                    <div class="pointinfo">
                      本节测试 {{ point.totalnum }} 次 / 最高分
                      {{ point.max }} / 最低分 {{ point.min }} / 平均分
                      {{ point.avg }} , 共 {{ point.usertotalnum }}人参加测试
                    </div>
                  </van-collapse-item>
                </van-collapse> -->
              </div>
              <div class="courseinfo">
                测试 {{ course.totalnum }} 次 / 最高分 {{ course.max }} / 最低分
                {{ course.min }} / 平均分 {{ course.avg }}
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </ul>
    <van-loading v-else size="24.001px" vertical>测试信息加载中...</van-loading>
  </div>
</template>

<script>
import Testinfopoint from '@/components/common/Testinfopoint'
export default ({
  data () {
    return {
      activeName: '1',
      pointname: '1'
    }
  },
  components: { Testinfopoint },
  mounted () {
    if (this.$store.state.systeminfo.test.length === 0) {
      this.$store.dispatch('test', {
        uid: localStorage.getItem('uid'),
        classid: localStorage.getItem('classid')
      })
    }
  },
  computed: {
    Lists: {
      get () {
        return this.$store.state.systeminfo.test
      },
      set () { }
    }
  }
})
</script>

<style lang="scss" scoped>
.list {
  color: chocolate;
  font-size: 3.611vw;
  padding-left: 2.778vw;
  padding-top: 0.833vw;
  padding-bottom: 0.833vw;
  border-bottom: 0.278vw dotted chocolate;
  text-align: left;
}
::v-deep .van-cell__title {
  text-align: left;
}
.courseinfo {
  color: rgb(255, 255, 255);
  background-color: coral;
  font-size: 3.611vw;
  padding-top: 1.389vw;
  padding-bottom: 1.389vw;
  padding-left: 2.778vw;
  text-align: left;
}
.pointinfo {
  color: cornflowerblue;
  background-color: cornsilk;
  font-size: 3.611vw;
  padding-top: 1.389vw;
  padding-bottom: 1.389vw;
  padding-left: 2.778vw;
  text-align: left;
}
</style>
