<template>
  <div>
    <div class="title">一共{{ Lists.length }}人打卡。</div>
    <ul
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
      v-if="Lists"
    >
      <div class="van-clearfix">
        <div class="float-item" v-for="(item, index) in Lists" :key="index">
          <div class="list">
            <b> {{ item.name }}</b> 一共打卡 {{ item.userclockintimes }} 次
          </div>
        </div>
      </div>
    </ul>
    <van-loading v-else size="24.001px" vertical
      >所有打卡信息加载中...</van-loading
    >
  </div>
</template>

<script>
export default ({
  data () {
    return {
      activeName: '1'
    }
  },
  mounted () {
    if (this.$store.state.systeminfo.Allclock.length === 0) {
      this.$store.dispatch('Allclock', {
        uid: localStorage.getItem('uid'),
        classid: localStorage.getItem('classid')
      })
    }
  },
  computed: {
    Lists: {
      get () {
        return this.$store.state.systeminfo.Allclock
      },
      set () { }
    }
  }
})
</script>

<style lang="scss" scoped>
.title {
  color: chocolate;
  padding: 1.389vw;
  text-align: center;
  font-size: 3.611vw;
  background-color: cornsilk;
}
.list {
  color: chocolate;
  font-size: 3.611vw;
  padding-left: 2.778vw;
  padding-top: 0.833vw;
  padding-bottom: 0.833vw;
  border-bottom: 0.278vw dotted chocolate;
  text-align: left;
}
</style>
