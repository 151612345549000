<template>
  <div>
    <van-collapse v-model="pointname" accordion>
      <van-collapse-item :title="this.title" :name="point.pointid">
        <div v-for="user in point.usergroup" :key="user.uid">
          <div class="list">
            {{ user.name }} 测试 {{ user.totalnum }} 次 / 最高分
            {{ user.max }} / 最低分 {{ user.min }} / 平均分
            {{ user.avg }}
          </div>
        </div>
        <!-- <div class="pointinfo">
          本节测试 {{ point.totalnum }} 次 / 最高分 {{ point.max }} / 最低分
          {{ point.min }} / 平均分 {{ point.avg }} , 共
          {{ point.usertotalnum }}人参加测试
        </div> -->
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>

export default ({
  data () {
    return {
      pointname: '2'
    }
  },
  props: ['point'],
  computed: {
    title: {
      get () {
        return '甲' + this.point.ord + '：' + this.point.pname + ': (测试 ' + this.point.totalnum + ' 次 / 最高分 ' + this.point.max + ' / 最低分 ' + this.point.min + ' / 平均分 ' + this.point.avg + ' , 共 ' + this.point.usertotalnum + ' 人参加测试 )'
      },
      set () { }
    }
  }
})
</script>
<style lang="scss" scoped>
::v-deep .van-cell__title {
  color: rgb(116, 118, 122);
  background-color: rgb(245, 246, 242);
  font-size: 3.611vw;
  padding-top: 1.389vw;
  padding-bottom: 1.389vw;
  padding-left: 0.08vw;
  text-align: left;
}
.list {
  color: rgb(165, 146, 132);
  font-size: 3.611vw;
  width: 100;
  padding-left: 0.08vw;
  padding-top: 0.833vw;
  padding-bottom: 0.833vw;
  border-bottom: 0.278vw dotted chocolate;
  text-align: left;
}
</style>
