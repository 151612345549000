<template>
  <div id="content" :style="backgroundDiv">
    <!-- <NavbarCommon :title="title"></NavbarCommon> -->
    <Systeminfo v-if="this.classid"></Systeminfo>
    <div v-else>
      <van-notice-bar left-icon="volume-o" text="没有报名？" />
      <van-cell :title="title" is-link class="vancell" @click="joinclass" />
    </div>
  </div>
</template>

<script>
// import Game from '@/components/game/Game'
import Systeminfo from '@/components/common/Systeminfo'
export default {
  components: {
    Systeminfo
  },
  data () {
    return {
      classid: '',
      title: '先去报名吧',
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/pres.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      height: ''
    }
  },
  mounted () {
    this.classid = localStorage.getItem('classid')
  },
  methods: {
    joinclass () {
      this.$router.push('/joinclass')
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
.vancell {
  //   border-top: 0.278vw #ccc solid;
  background-color: rgba(255, 255, 255, 0.959);
  margin-top: 4.167vw;
  margin-bottom: 2.778vw;
}
::v-deep .van-cell__title {
  color: chocolate;
}
</style>
